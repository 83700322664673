// must be length 16!
const ANIMAL_NAMES = [
    "antsy ant",
    "bouncing bull",
    "cold chicken",
    "devil dog",
    "flippin fish",
    "feisty fox",
    "heckin horse",
    "krispy kangaroo",
    "lyin lion",
    "mad monkey",
    "puzzled penguin",
    "perfect piglet",
    "rascally wrabbit",
    "silly sheep",
    "whaaaaaale",
    "where wolf",
]

export async function uuid_to_animal(uuid: string): Promise<string> {
    const encoder = new TextEncoder();
    const hash = await crypto.subtle.digest("SHA-256", encoder.encode(uuid));
    const index = ((new Uint8Array(hash))[0] & 0xF0) >>> 4;
    return ANIMAL_NAMES[index];
}

export async function get_default_uuid(): Promise<string> {
    return fetch('https://duo.haus/beatrice/organization/id')
        .then((response) => {
            return response.text()
        })
        .then((response_text) => {
            return response_text.replace(/['"]+/g, '')
        })
}

interface PairingIDAndPolicyState {
    uuid: string,
    policyEnabled: boolean,
}

export async function get_pairings_for_uuid(uuid: string): Promise<PairingIDAndPolicyState[]> {
    return fetch('https://duo.haus/pairs/' + uuid)
        .then((response) => {
            return response.json()
        })
        .then((response_json) => {
            const pairingUUIDs: PairingIDAndPolicyState[] = [];
            // response_json is a list of authenticator objects
            // they should have unique pairings...
            response_json.forEach((authenticator: any) => {
                if ("pairs" in authenticator && authenticator["pairs"].length > 0)
                pairingUUIDs.push({
                    uuid: authenticator["pairs"][0]["id"],
                    policyEnabled: authenticator["beatrice_policy_fail"],
                })
            });
            return pairingUUIDs;
        })
}