import React, { ChangeEvent } from 'react';
import './App.css';
import "react-toggle/style.css"
import Toggle from 'react-toggle';
import * as util from './lib/util';

interface AppProps {
}

interface AppState {
  policyList: any[],
}

class App extends React.Component<AppProps, AppState> {
  readonly state = {
    policyList: [],
  }

  constructor(props: AppProps) {
    super(props);

    const policyList: any[] = [];
    util.get_default_uuid().then((uuid) => {
      util.get_pairings_for_uuid(uuid).then((pairs) => {
        for (let index = 0; index < pairs.length; index++) {
          policyList.push(<PolicyEntry uuid={pairs[index].uuid} policyInitiallyEnabled={pairs[index].policyEnabled} />)
        }
        this.setState({
          policyList: policyList,
        })
      })
    })
  }

  // fetch('https://duo.haus/api/pairing/list')
  //   .then((response) => {
  //     return response.json()
  //   })
  //   .then((response_json) => {
  //     console.log(response_json)
  //     const pairings = response_json['pairings']
  //     for (let index = 0; index < pairings.length; index++) {
  //       const pairing = pairings[index];
  //       policyList.push(<PolicyEntry name="test" />)
  //     }
  //   })

  render() {
    return (
      <div>
        {this.state.policyList}
      </div>
    )
  }
}

interface PolicyEntryProps {
  uuid: string,
  policyInitiallyEnabled: boolean,
}

interface PolicyEntryState {
  policyEnabled: boolean,
  animalName?: string;
}

class PolicyEntry extends React.Component<PolicyEntryProps, PolicyEntryState> {
  readonly state = {
    policyEnabled: this.props.policyInitiallyEnabled,
    animalName: undefined,
  }

  constructor(props: PolicyEntryProps) {
    super(props);
    this.get_animal_name(this.props.uuid).then((animal_name) => {
      console.log("animal name for " + this.props.uuid + " is " + animal_name)
      this.setState({
        animalName: animal_name,
      }, () => {
        console.log("state updated")
      })
    })
  }

  handlePolicyToggled = (event: ChangeEvent<HTMLInputElement>): string => {
    this.setState({
      policyEnabled: event.target.checked,
    })
    console.log("policy is now " + event.target.checked)
    const fetchEndpoint = 'https://duo.haus/beatrice/policy/' + this.props.uuid + '/' + (event.target.checked ? 'true' : 'false')
    fetch(fetchEndpoint, {
      method: 'POST',
    })
    return ""
  }

  async get_animal_name(uuid: string): Promise<string> {
    return await util.uuid_to_animal(uuid)
  }

  render() {
    let name: string;
    if (this.state.animalName) {
      name = this.state.animalName as any; // we're sure it's a string
    } else {
      name = this.props.uuid;
    }

    return (
      <PolicyToggle
        name={name}
        policyEnabled={this.state.policyEnabled}
        onClick={this.handlePolicyToggled}
      />
    )
  }
}

interface PolicyToggleProps {
  name: string,
  policyEnabled: boolean,
  onClick: (event: ChangeEvent<HTMLInputElement>) => string,
}

interface PolicyToggleState {
}

class PolicyToggle extends React.Component<PolicyToggleProps, PolicyToggleState> {
  render() {
    return ([
      <div className="policy-entry">
        <label>
          <Toggle
            defaultChecked={this.props.policyEnabled}
            onChange={this.props.onClick} />
          <span className="label-text"><b>Enable policy:</b> {this.props.name}</span>
        </label>
      </div>
    ])
  }
}

export default App;
